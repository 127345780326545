import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  FormLabel,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import InfoIcon from '@material-ui/icons/Info';
import { ErrorBox } from 'plugin-ui-components';
import { containerImagesApiRef } from 'plugin-core';

import { Layout } from '../components';

const IMAGE_REG =
  /^([^/\s]+)\/([^/\s]+)?(\/([^:/\s]+))?\/([^:/\s]+)(:?([^/\s]+))?$/i;
const IMAGE_REG_ERROR_MSG = `
  Wrong image format. Expected one of:
  'registry/namespace/name', 'registry/namespace/name:tag',
  'registry/namespace/name/name', 'registry/namespace/name/name:tag'.
  `;

function Content() {
  const [image, setImage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const navigate = useNavigate();
  const api = useApi(containerImagesApiRef);

  function handleSearch(e: React.FormEvent) {
    e.preventDefault();
    setError(undefined);

    const imageParts = IMAGE_REG.exec(image);
    if (imageParts === null) {
      setError(new Error(IMAGE_REG_ERROR_MSG));
      return;
    }
    setIsLoading(true);
    const registry = imageParts[1];
    const namespace = imageParts[2];
    const tag = imageParts[imageParts.length - 1] || 'latest';
    const name = imageParts[4]
      ? `${imageParts[4]}/${imageParts[5]}`
      : imageParts[5];

    api
      .getImage(registry, `${namespace}/${name}`, tag)
      .then(resp => {
        setIsLoading(false);
        if (!!resp.origin_name) {
          if (!tag) {
            navigate(`/container-images/${registry}/${namespace}/${name}`);
          } else {
            navigate(
              `/container-images/${registry}/${namespace}/${name}?tag=${tag}`,
            );
          }
          return;
        }
        setError(new Error("Image data couldn't be found."));
      })
      .catch(err => {
        setIsLoading(false);
        setError(err);
      });
  }

  function handleChange(e: React.ChangeEvent<{ value: string }>) {
    if (error !== undefined) setError(undefined);
    setImage((e.target.value || '').trim());
  }

  return (
    <Box
      textAlign="center"
      width="100%"
      maxWidth="900px"
      margin="0 auto"
      padding={6}
    >
      <Typography variant="h1">🚧</Typography>
      <Typography variant="h3">
        Container images plugin under construction
      </Typography>

      <Box paddingTop={3} paddingBottom={3}>
        <form onSubmit={handleSearch}>
          <FormLabel
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              lineHeight: 0,
              textTransform: 'uppercase',
              fontSize: '12px',
              fontWeight: 600,
              paddingBottom: '5px',
            }}
          >
            <span>Find image</span>
            <Tooltip title={<TooltipContent />}>
              <span>
                <InfoIcon style={{ width: '16px', marginLeft: '5px' }} />
              </span>
            </Tooltip>
          </FormLabel>

          <Box display="flex" alignItems="center" justifyContent="center">
            <TextField
              variant="outlined"
              style={{ width: '100%' }}
              type="text"
              size="small"
              placeholder="e.g. container-registry.zalando.net/automata/cdp-deploy-sched-flyway"
              onChange={handleChange}
              value={image}
              disabled={isLoading}
              inputProps={{ maxLength: 200 }}
            />

            <Button
              style={{
                marginLeft: '10px',
                padding: '7px 15px',
              }}
              disabled={isLoading}
              variant="outlined"
              color="primary"
              type="submit"
            >
              find
            </Button>
          </Box>
        </form>
      </Box>

      {isLoading && <CircularProgress />}

      {error && <ErrorBox description={error.message} />}
    </Box>
  );
}

function TooltipContent() {
  return (
    <div>
      <p>Supported formats:</p>
      <ul>
        <li>registry/namespace/name</li>
        <li>registry/namespace/name:tag</li>
        <li>registry/namespace/name/name</li>
        <li>registry/namespace/name/name:tag</li>
      </ul>
    </div>
  );
}

export function MainPage() {
  return (
    <Layout
      render={<Content />}
      title="Container Images"
      subtitle="Browse Zalando Container Registry (ZCR) and Pierone images"
    />
  );
}
