export enum ToolOrganization {
  ZalandoDataFoundation = 'Zalando Data Foundation',
  BuilderInfrastructure = 'Builder Infrastructure',
}

export enum ToolCNCFCategories {
  // Application Definition and Development
  ApplicationDefinitionAndImageBuild = 'Application Definition & Image Build',
  ContinuousIntegrationAndDelivery = 'Continuous Integration & Delivery',
  Database = 'Database',
  StreamingAndMessaging = 'Streaming & Messaging',
  // Observability and Analysis
  ChaosEngineering = 'Chaos Engineering',
  ContinuousOptimization = 'Continuous Optimization',
  FeatureFlagging = 'Feature Flagging',
  Observability = 'Observability',
  // Orchestration and Management
  APIGateway = 'API Gateway',
  CoordinationAndServiceDiscovery = 'Coordination & Service Discovery',
  RemoteProcedureCall = 'Remote Procedure Call',
  SchedulingAndOrchestration = 'Scheduling & Orchestration',
  ServiceMesh = 'Service Mesh',
  ServiceProxy = 'Service Proxy',
  // Provisioning
  AutomationAndConfiguration = 'Automation & Configuration',
  ContainerRegistry = 'Container Registry',
  KeyManagement = 'Key Management',
  SecurityAndCompliance = 'Security & Compliance',
  // Runtime
  CloudNativeNetwork = 'Cloud Native Network',
  CloudNativeStorage = 'Cloud Native Storage',
  ContainerRuntime = 'Container Runtime',
  // Machine Learning
  MLExperimentation = 'ML Experimentation',
  MLProductionServing = 'ML Production Serving',
  // Data
  Discovery = 'Discovery',
  AnalyticsAndExploration = 'Analytics & Exploration',
  Serving = 'Serving',
  ProcessingAndTransformation = 'Processing & Transformation',
  DataOps = 'DataOps',
  // Productivity & Learning
  Productivity = 'Productivity',
  LearningAndGrowth = 'Learning & Growth',
}
