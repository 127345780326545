import React from 'react';
import {
  FormControl,
  Typography,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = 'Tech Teams';
const id = 'teams-filter';

export const TechTeamsFilter = React.memo(() => {
  const { techTeamsFilter, setTechTeamsFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot: select } = useStyles();

  return (
    <FormControl variant="outlined" margin="dense">
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        label={label}
        labelId={id}
        value={techTeamsFilter || ''}
        classes={{ select }}
        onChange={ev => setTechTeamsFilter(ev.target.value as any)}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem>
      </Select>
      <Typography variant="caption">
        Displays only teams that contain a tech ID/Alias
      </Typography>
    </FormControl>
  );
});
