import React from 'react';
import { Link, TableColumn } from '@backstage/core-components';
import { Box, Chip, Typography } from '@material-ui/core';
import { Tracking } from '../../../tracking';
import { RowActions } from './RowActions';
import { StepCell } from './StepCell';
import {
  ProductOrService,
  ProductOrServiceJourney,
  ProductOrServiceStatus,
} from '../types';
import {
  EventTracker,
  OverflowTooltip,
  TeamReference,
} from 'plugin-ui-components';
import { useStyles } from '../styles';
import rings from './rings';
import { ProductStatus } from './ProductStatus';

const runningLightColor = '#ADB94F';
const purpleLightColor = '#B864C5';

export const chipBg = (journey: ProductOrServiceJourney) =>
  journey === 'Development' ? runningLightColor : purpleLightColor;

export const columns: TableColumn<ProductOrService>[] = [
  {
    title: 'Product/Service',
    field: 'title',
    highlight: true,
    render: (row: ProductOrService) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const classes = useStyles();

      return (
        <Box width="300px">
          <span className={classes.titleColumn}>
            {row.interface ? (
              <EventTracker {...Tracking.viewLink(`${row.title}`)}>
                <Link to={row.interface}>{row.title}</Link>
              </EventTracker>
            ) : (
              <Typography>{row.title}</Typography>
            )}

            <OverflowTooltip
              text={row.purpose}
              title={row.purpose || ''}
              placement="bottom-start"
              TypographyProps={{
                variant: 'body2',
              }}
              fullWidth
            />
          </span>
        </Box>
      );
    },
  },
  {
    title: 'Purpose',
    field: 'purpose',
    hidden: true,
    searchable: true,
  },
  {
    title: 'Status',
    field: 'status',
    defaultSort: 'asc',
    align: 'center',
    customSort: (a: ProductOrService, b: ProductOrService) => {
      if (a.status === b.status) {
        return a.title > b.title ? 1 : -1;
      }
      return (rings[a.status].sortingWeight ?? 5) >
        (rings[b.status].sortingWeight ?? 5)
        ? 1
        : -1;
    },
    render: ProductStatus,
  },
  {
    title: 'Journey',
    field: 'journey',
    sorting: false,
    align: 'center',
    render: row => (
      <div className="spaced-flex">
        {row.journey.map((item, i) => (
          <Chip
            key={i}
            style={{ backgroundColor: chipBg(item) }}
            color="primary"
            label={item}
            variant="default"
          />
        ))}
      </div>
    ),
  },
  {
    title: <StepCell />,
    sorting: false,
    align: 'center',
    render: row => (
      <div className="spaced-flex">
        {row.step.map((item, i) =>
          item ? (
            <Chip
              key={i}
              style={{ backgroundColor: chipBg(row.journey[0]) }}
              color="primary"
              label={item}
              variant="default"
            />
          ) : (
            ''
          ),
        )}
      </div>
    ),
  },
  {
    title: 'Owner',
    field: 'owner',
    defaultSort: 'asc',
    align: 'center',
    render: row => <TeamReference team={row.sap_id} format="nameOnly" />,
  },
  {
    title: 'Helpful Links',
    sorting: false,
    align: 'center',
    render: RowActions,
  },
];

export const toolingCatalogColumns: TableColumn<IEntityTooling>[] = [
  {
    title: 'Product/Service',
    field: 'spec.title',
    highlight: true,
    render: entity => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const classes = useStyles();
      const url = `/catalog/default/Tooling/${entity.metadata.name}`;

      return (
        <Box width="300px">
          <span className={classes.titleColumn}>
            <EventTracker {...Tracking.viewLink(`${entity.spec.title}`)}>
              <Link to={url}>{entity.spec.title}</Link>
            </EventTracker>

            <OverflowTooltip
              text={entity.spec.purpose}
              title={entity.spec.purpose || ''}
              placement="bottom-start"
              TypographyProps={{
                variant: 'body2',
              }}
              fullWidth
            />
          </span>
        </Box>
      );
    },
  },
  {
    title: 'Purpose',
    field: 'spec.purpose',
    hidden: true,
    searchable: true,
  },
  {
    title: 'Status',
    field: 'spec.status',
    defaultSort: 'asc',
    align: 'center',
    customSort: (a: IEntityTooling, b: IEntityTooling) => {
      if (a.spec.status === b.spec.status) {
        return a.spec?.title > b.spec?.title ? 1 : -1;
      }
      return (rings[a.spec.status as ProductOrServiceStatus].sortingWeight ??
        5) > (rings[b.spec.status as ProductOrServiceStatus].sortingWeight ?? 5)
        ? 1
        : -1;
    },
    render: entity => (
      <ProductStatus status={entity.spec.status as ProductOrServiceStatus} />
    ),
  },
  {
    title: 'Journey',
    field: 'entity.spec.journey',
    sorting: false,
    align: 'center',
    render: entity => (
      <div className="spaced-flex">
        {entity.spec?.journey?.map((item, i) => (
          <Chip
            key={i}
            style={{
              backgroundColor:
                entity.spec.journey[i] === 'Development'
                  ? runningLightColor
                  : purpleLightColor,
            }}
            color="primary"
            label={item}
            variant="default"
          />
        ))}
      </div>
    ),
  },
  {
    title: <StepCell />,
    sorting: false,
    align: 'center',
    render: entity => (
      <div className="spaced-flex">
        {entity.spec.step.map((item, i) =>
          item ? (
            <Chip
              key={i}
              style={{
                backgroundColor:
                  entity.spec.journey[0] === 'Development'
                    ? runningLightColor
                    : purpleLightColor,
              }}
              color="primary"
              label={item}
              variant="default"
            />
          ) : (
            ''
          ),
        )}
      </div>
    ),
  },
  {
    title: 'Owner',
    field: 'spec.owner',
    defaultSort: 'asc',
    align: 'center',
    render: entity => (
      <TeamReference team={entity.spec.sapId} format="nameOnly" />
    ),
  },
  {
    title: 'Helpful Links',
    sorting: false,
    align: 'center',
    render: entity => <RowActions {...entity.spec} />,
  },
];
