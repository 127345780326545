import {
  createApiFactory,
  createPlugin,
  createRouteRef,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import ZflowAPIClient, { zflowApiRef } from './api/zflowApiClient';
import { mlExperimentationApiRefs } from 'plugin-ml-experimentation';
import { oauth2ApiRef } from 'plugin-core';

export const rootRouteRef = createRouteRef({ id: 'ml' });

export const MLPlatformPlugin = createPlugin({
  id: 'ml-platform',
  apis: [
    createApiFactory({
      api: zflowApiRef,
      deps: {
        oauth2Api: oauth2ApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ oauth2Api, discoveryApi }) =>
        new ZflowAPIClient({
          oauth2Api: oauth2Api,
          discoveryApi: discoveryApi,
        }),
    }),
    ...mlExperimentationApiRefs,
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const MLPlatformRouter = MLPlatformPlugin.provide(
  createRoutableExtension({
    name: 'plugin-ml-platform',
    component: () => import('./router').then(m => m.MLPlatformRouter),
    mountPoint: rootRouteRef,
  }),
);
