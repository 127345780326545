import React from 'react';
import { useAsync } from 'react-use';
import { useParams, useSearchParams } from 'react-router-dom';
import { useApi } from '@backstage/core-plugin-api';
import { Content } from '@backstage/core-components';
import { containerImagesApiRef } from 'plugin-core';

import { ImageOverview } from '../../components';
import { ImageModel } from '../../models';

export function ImagOverview() {
  const { registry = '', namespace = '', name = '', name2 } = useParams();
  const [searchParams, _] = useSearchParams();
  const api = useApi(containerImagesApiRef);

  const path = name2 ? `${namespace}/${name}/${name2}` : `${namespace}/${name}`;
  const tag = searchParams.get('tag') || 'latest';

  const {
    value: image,
    loading,
    error,
  } = useAsync(() => api.getImage(registry, path, tag));

  return (
    <Content>
      <ImageOverview
        image={new ImageModel(image)}
        isLoading={loading}
        error={error}
      />
    </Content>
  );
}
