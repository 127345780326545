import {
  StatusAborted,
  StatusError,
  StatusOK,
  StatusPending,
  StatusWarning,
} from '@backstage/core-components';
import { Tooltip, Typography } from '@material-ui/core';
import { EventTracker, HStack } from 'plugin-ui-components';
import React from 'react';
import rings from './rings';
import { Tracking } from '../../../tracking';
import { ProductOrServiceStatus } from '../types';

const Statuses: Record<ProductOrServiceStatus, React.FC<any>> = {
  Adopt: StatusOK,
  Trial: StatusPending,
  Assess: StatusWarning,
  Hold: StatusAborted,
  Retired: StatusError,
} as Record<ProductOrServiceStatus, React.FC<any>>;

interface Props {
  status: ProductOrServiceStatus;
}

export function ProductStatus({ status }: Props) {
  if (Statuses.hasOwnProperty(status)) {
    const StatusComp = Statuses[status];
    return (
      <Tooltip title={rings[status]?.description}>
        <Typography>
          <EventTracker {...Tracking.hoverStatus(`${rings[status]?.name}`)}>
            <HStack
              alignItems="center"
              spacing="8px"
              flexWrap="wrap"
              justifyContent="center"
            >
              <StatusComp>
                <span>{rings[status]?.name}</span>
              </StatusComp>
            </HStack>
          </EventTracker>
        </Typography>
      </Tooltip>
    );
  }
  return <Typography>{rings[status].name}</Typography>;
}
