import {
  DiscoveryApi,
  OAuthApi,
  createApiRef,
} from '@backstage/core-plugin-api';
import { Operation } from './types';

export class OperationBasedSLOsApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly oauth2Api: OAuthApi;
  // private readonly configApi: ConfigApi;

  constructor(options: { discoveryApi: DiscoveryApi; oauth2Api: OAuthApi }) {
    this.discoveryApi = options.discoveryApi;
    this.oauth2Api = options.oauth2Api;
  }

  async fetchServiceLevelObjectives(): Promise<Operation[]> {
    const token = await this.oauth2Api.getAccessToken();
    const baseUrl = await this.discoveryApi.getBaseUrl(
      'service-level-objectives',
    );
    const apiUrl = `${baseUrl}/opslos`;

    const response = await fetch(apiUrl, {
      method: 'GET',
      credentials: 'include',
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await response.json();
    return data.items;
  }
}

export const operationBasedSLOsApiRef = createApiRef<OperationBasedSLOsApi>({
  id: 'plugin.opsgenie.service-level-objectives',
});
