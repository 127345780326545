import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { RkeepApiClient, rkeepApiRef } from './api/RkeepApi';
import { oauth2ApiRef } from 'plugin-core';

export const rkeepPlugin = createPlugin({
  id: 'rkeep',
  routes: {
    root: rootRouteRef,
  },
  featureFlags: [{ name: 'rkeep-ui' }],
  apis: [
    createApiFactory({
      api: rkeepApiRef,
      deps: {
        oauth2Api: oauth2ApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ discoveryApi, oauth2Api }) =>
        new RkeepApiClient(discoveryApi, oauth2Api),
    }),
  ],
});

export const RkeepPage = rkeepPlugin.provide(
  createRoutableExtension({
    name: 'RkeepPage',
    component: () =>
      import('./components/RkeepRoot').then(m => m.RkeepRootPage),
    mountPoint: rootRouteRef,
  }),
);
