export default `
query AISystems($application_id: String, $limit: Int, $offset: Int, $name: String, $purpose: String, $team_id: String, $impact: String, $category: String, $input_data: String, $output_data: String, $zalando_role: String,  $include: [String!]
) {
meta {
    application_ids
    totals
  }
  ai_systems(
    application_id: $application_id
    name: $name
    limit: $limit
    offset: $offset
    include: $include
    purpose: $purpose
    team_id: $team_id
    impact: $impact
    category: $category
    zalando_role: $zalando_role
    output_data: $output_data
    input_data: $input_data
  ) {
    name
    team_id
    description
    ai_system_id
    description
    category
    input_data
    output_data
    purpose
    zalando_role
    impact
    documentation
    meta
    created_at
    created_by{
      uid
      realm
    }
    updated_at
    updated_by{
      uid
      realm
    }
    applications
    custom_values
  }
}`;
