import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { oauth2ApiRef } from 'plugin-core';
import { gitHubApiRef, GitHubClient } from './api';

export const githubPlugin = createPlugin({
  id: 'github',
  apis: [
    createApiFactory({
      api: gitHubApiRef,
      deps: { authApi: oauth2ApiRef, discoveryApi: discoveryApiRef },
      factory: ({ authApi, discoveryApi }) =>
        new GitHubClient({ authApi, discoveryApi }),
    }),
  ],
});

// Lazy load the UI components
export const ApplicationVulnerabilities = githubPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () =>
        import('./components/ApplicationVulnerabilities').then(
          m => m.ApplicationVulnerabilities,
        ),
    },
  }),
);

export const DependabotAlertSeveritiesChip = githubPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () =>
        import('./components/DependabotAlertSeveritiesChip').then(
          m => m.DependabotAlertSeveritiesChip,
        ),
    },
  }),
);

export const DependabotAlertsTable = githubPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () =>
        import('./components/DependabotAlertsTable').then(
          m => m.DependabotAlertsTable,
        ),
    },
  }),
);
