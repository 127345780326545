import { IRunGroups, IRuns } from '../../api/types/payload';
import { IWithRepoInfo, RepositoryModel } from '../repository';
import { RunsStepModel } from './step.model';
import { RunsTagsModel } from './tags.model';

class BaseRunsModel implements IWithRepoInfo {
  id: string;
  created_at: string;
  triggered_at: string;
  finished_at: string;
  status: string;
  build_version: string;
  commit_message: string;
  commit_id: string;
  pr_title: string;
  pr_number: number;
  target_repo: string;
  retriggered_from_pipeline_build_version: string;
  is_head: boolean;
  error: string;
  steps: Array<RunsStepModel>;
  tags: RunsTagsModel;
  triggered_by_dependency: string;
  dependency_vars: { [key: string]: string };

  isPullRequest: boolean;
  repoInfo: RepositoryModel;

  isRetriggered: boolean;
  isTriggeredByDependency: boolean;
  isSchedule: boolean;

  hasDependencyVars: boolean;

  constructor(json: IRuns = {} as IRuns) {
    this.id = json.id ?? '';
    this.created_at = json.created_at ?? '';
    this.triggered_at = json.triggered_at ?? '';
    this.finished_at = json.finished_at ?? '';
    this.status = json.status ?? '';
    this.build_version = json.build_version ?? '';
    this.commit_message = json.commit_message ?? '';
    this.commit_id = json.commit_id ?? '';
    this.pr_title = json.pr_title ?? '';
    this.pr_number = json.pr_number ?? 0;
    this.target_repo = json.target_repo ?? '';
    this.is_head = json.is_head ?? false;
    this.retriggered_from_pipeline_build_version =
      json.retriggered_from_pipeline_build_version ?? '';
    this.error = json.error ?? 'No steps in pipeline';
    this.isPullRequest = Boolean(this.pr_number);
    this.triggered_by_dependency = json.triggered_by_dependency ?? '';
    this.dependency_vars = json.dependency_vars ?? {};

    this.steps = [];
    if (json.steps) {
      json.steps.map(step => this.steps.push(new RunsStepModel(step ?? {})));
    }

    this.tags = new RunsTagsModel(json.tags ?? {});
    this.repoInfo = RepositoryModel.fromFullPath(this.tags.repo);

    this.isSchedule = this.tags.event === 'schedule';
    this.isRetriggered = Boolean(
      this.retriggered_from_pipeline_build_version &&
        !this.triggered_by_dependency &&
        this.tags.event !== 'schedule',
    );
    this.isTriggeredByDependency = Boolean(this.triggered_by_dependency);
    this.hasDependencyVars = Boolean(
      Object.keys(json.dependency_vars ?? {}).length,
    );
  }
}

export class RunsModel extends BaseRunsModel {}

export class RunGroupsModel extends BaseRunsModel {
  repo_full_name: string;

  constructor(json: IRunGroups = {} as IRunGroups) {
    super(json);

    this.repo_full_name = json.repo_full_name ?? '';
  }
}
