import React from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { FilterGroupsContext, PluginTracking } from 'plugin-ui-components';
// import { addFilter } from '../tracking';
import { useStyles } from '../styles';
import { addFilter } from './tracking';

export const CostCentersFilter = React.memo(() => {
  const { availableCostCenters, costCenterFilter, setCostCenterFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot } = useStyles();

  const label = 'Cost Centers';

  return (
    <FormControl>
      <Autocomplete
        multiple
        options={availableCostCenters}
        value={costCenterFilter}
        onChange={(_: any, value) => {
          setCostCenterFilter(value as string[]);
          if (!!value.length) {
            PluginTracking.sendEvent({
              ...addFilter(label, value as string[]),
            });
          }
        }}
        classes={{ inputRoot }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            label={label}
            {...params}
            variant="outlined"
            margin="dense"
          />
        )}
      />
    </FormControl>
  );
});
