import { ProductRings } from '../types';

const rings: ProductRings = {
  Adopt: {
    name: 'ADOPT',
    color: 'primary',
    description:
      'Tools or services in which we have high confidence that they serve our purpose, strategy, and vision—also at Zalando’s scale. Products with a usage culture in our Zalando production environment which are low risk and recommended to be widely used, and thus are well supported.',
    sortingWeight: 0,
  },
  Trial: {
    name: 'TRIAL',
    color: 'primary',
    description:
      'Tools or services that we have seen work with success in project work to solve a real problem; we observe serious usage and experiences that confirm its benefits and can uncover limitations, but the community around them is still growing; some engineers in our organization walked this path and will share knowledge and experiences.',
    sortingWeight: 1,
  },
  Assess: {
    name: 'ASSESS',
    color: 'primary',
    description:
      'Tools or services that are promising and have clear potential value-add for us; it’s likely worth it to invest some research and prototyping efforts to evaluate the impact. ASSESS products have higher risks; they are often brand new and may be highly unproven at scale in our organization. You will find some engineers that have knowledge in the technology and promote it, you may even find teams that have started a prototyping effort.',
    sortingWeight: 2,
  },
  Hold: {
    name: 'HOLD',
    color: 'primary',
    description:
      'Tools or services which are not recommended to be used for new projects, but usually can be continued for existing projects. Currently, we think these Tools or services are not worth further investments; we may even be looking for a replacement, so its description might include alternative offerings. Migrations should be considered.',
    sortingWeight: 3,
  },
  Retired: {
    name: 'RETIRED',
    color: 'primary',
    description:
      'Tools or services which have recently been retired. Its functionalities are no longer available for use on ANY project and support is no longer available from the team who used to own it. Please see the description for details about replacement / alternative solutions for your use case.',
    sortingWeight: 4,
  },
  unknown: {
    name: 'UNKNOWN',
    color: '#efafa9',
    description: '',
    sortingWeight: 4,
  },
};

export default rings;
