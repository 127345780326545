import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  createRouteRef,
} from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({ id: 'search' });

export const plugin = createPlugin({
  id: 'search',
  routes: {
    root: rootRouteRef,
  },
});

export const SearchPage = plugin.provide(
  createRoutableExtension({
    name: 'plugin-search',
    component: () => import('./components/SearchPage').then(m => m.SearchPage),
    mountPoint: rootRouteRef,
  }),
);

// Lazy load the UI components
export const SearchModal = plugin.provide(
  createComponentExtension({
    component: {
      lazy: () => import('./components/SearchModal').then(m => m.SearchModal),
    },
  }),
);
