import axios, { AxiosError } from 'axios';
import { DiscoveryApi, OAuthApi } from '@backstage/core-plugin-api';
import {
  DocQuestions,
  SearchResult,
  type Namespace,
  type StoredAnswer,
} from './types';
import { DEFAULT_NAMESPACE } from './constants';

export class NLIError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

export class NLInterfaceApiClient {
  public readonly oauth2Api: OAuthApi;
  public readonly discoveryApi: DiscoveryApi;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  async getDocQuestions(count: number): Promise<DocQuestions> {
    const baseUrl = await this.discoveryApi.getBaseUrl('nl-interface');
    try {
      const response = await axios.get(`${baseUrl}/questions?count=${count}`, {
        headers: {
          Authorization: `Bearer ${await this.oauth2Api.getAccessToken()}`,
        },
      });
      return response.data;
    } catch (error: any) {
      throw this.reportError(error);
    }
  }

  async getNamespaces(): Promise<Namespace[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('nl-interface');
    try {
      const response = await axios.get(`${baseUrl}/namespaces`, {
        headers: {
          Authorization: `Bearer ${await this.oauth2Api.getAccessToken()}`,
        },
      });
      return response.data;
    } catch (error: any) {
      throw this.reportError(error);
    }
  }

  async search(
    text: string,
    { namespace = DEFAULT_NAMESPACE }: Namespace,
  ): Promise<SearchResult> {
    const baseUrl = await this.discoveryApi.getBaseUrl('nl-interface');
    try {
      const response = await axios.post(
        `${baseUrl}/search`,
        { text, namespace },
        {
          headers: {
            Authorization: `Bearer ${await this.oauth2Api.getAccessToken()}`,
          },
        },
      );
      return response.data;
    } catch (error: any) {
      throw this.reportError(error);
    }
  }

  async createAnswerUrl(
    input: string,
    output: string,
    namespace: string,
  ): Promise<Pick<StoredAnswer, 'id'>> {
    const baseUrl = await this.discoveryApi.getBaseUrl('nl-interface');
    try {
      const response = await axios.post(
        `${baseUrl}/prompt`,
        { input, output, namespace },
        {
          headers: {
            Authorization: `Bearer ${await this.oauth2Api.getAccessToken()}`,
          },
        },
      );
      return response.data;
    } catch (error: any) {
      throw this.reportError(error);
    }
  }

  async getAnswerById(id: string): Promise<StoredAnswer> {
    const baseUrl = await this.discoveryApi.getBaseUrl('nl-interface');
    try {
      const response = await axios.get(`${baseUrl}/prompt/${id}`, {
        headers: {
          Authorization: `Bearer ${await this.oauth2Api.getAccessToken()}`,
        },
      });
      return response.data;
    } catch (error: any) {
      throw this.reportError(error);
    }
  }

  private reportError(error: AxiosError) {
    const message =
      (error?.response?.data as string) ?? error?.message ?? 'Unknown error';
    const status = error?.response?.status ?? 500;
    return new NLIError(message, status);
  }
}
