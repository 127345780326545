import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRouteRef,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { CyberweekApi, cyberweekApiRef } from './api';
import { oauth2ApiRef } from 'plugin-core';

export const rootRouteRef = createRouteRef({ id: 'cyberweek' });

export const plugin = createPlugin({
  id: 'cyberweek',
  apis: [
    createApiFactory({
      api: cyberweekApiRef,
      deps: { oauth2Api: oauth2ApiRef, discoveryApi: discoveryApiRef },
      factory: ({ oauth2Api, discoveryApi }) =>
        new CyberweekApi({
          oauth2Api,
          discoveryApi,
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

// Lazy load the UI components
export const CyberWeekPage = plugin.provide(
  createComponentExtension({
    name: 'CyberWeekPage',
    component: {
      lazy: () => import('./components').then(m => m.CyberWeekPage),
    },
  }),
);
