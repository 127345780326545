import React from 'react';
import Markdown from 'markdown-to-jsx';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Link, TableColumn } from '@backstage/core-components';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import { Entity } from '@backstage/catalog-model';
import { FavouriteEntity } from '../../FavouriteEntity/FavouriteEntity';
import { OverflowTooltip } from '../../../OverflowTooltip';
import { TeamReference } from '../../../TeamReference';
import { entitySort } from 'plugin-core';

/**
 * Shared column for Name/Description.
 */
export function createNameDescriptionColumn(
  options: { to?: (entity: Entity) => string } = {},
): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Name/Description',
    field: 'resolved.name',
    render: ({ entity }) => {
      const isActive = (entity as IEntityApp).spec?.spec?.active;
      const {
        kind = 'Component',
        metadata: { namespace = 'default', name },
      } = entity;
      return (
        <Box width="300px" display="flex" flexDirection="column" gap="0.5rem">
          <Box display="flex" alignItems="center">
            {isActive !== undefined && (
              <Tooltip title={isActive ? 'active' : 'inactive'}>
                <FiberManualRecord
                  className="dot-icon"
                  data-active={String(isActive)}
                />
              </Tooltip>
            )}
            <Link
              to={
                options.to
                  ? options.to(entity)
                  : `/catalog/${namespace}/${kind}/${name}`
              }
            >
              <strong>{name}</strong>
            </Link>
          </Box>
          <OverflowTooltip
            text={entity.metadata.description}
            title={<Markdown>{entity.metadata.description || ''}</Markdown>}
            placement="bottom-start"
            TypographyProps={{
              variant: 'body2',
            }}
            fullWidth
          />
        </Box>
      );
    },
  };
}

/**
 * Shared column for actions.
 */
export function createActionsColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: '',
    sorting: false,
    width: '0',
    render: ({ entity }) => {
      return (
        <Box display="flex" alignItems="center">
          <FavouriteEntity entity={entity} />
        </Box>
      );
    },
  };
}

/**
 * Shared column for hidden but searchable fields.
 */
export function createHiddenSearchableColumn(field: string) {
  return {
    field,
    hidden: true,
    searchable: true,
  };
}

export function createOwnerColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Owner',
    field: 'resolved.ownerTeamNameAndId',
    render: ({ entity }) => (
      <TeamReference team={(entity as IEntity).spec.owner} format="nameOnly" />
    ),
  };
}

export function createMetadataDescriptionColumn(
  order: 'asc' | 'desc' | '' = 'asc',
): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Description',
    field: 'entity.metadata.description',
    customSort: (a, b) =>
      entitySort(a.entity, b.entity, 'metadata.description', order),
    render: ({ entity }) => (
      <OverflowTooltip
        text={entity.metadata.description}
        title={<Markdown>{entity.metadata.description || ''}</Markdown>}
        placement="bottom-start"
        TypographyProps={{
          variant: 'body2',
        }}
        fullWidth
      />
    ),
    hidden: true,
    searchable: true,
  };
}
