import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const teamsCatalogPlugin = createPlugin({
  id: 'teams-catalog',
  routes: {
    root: rootRouteRef,
  },
});

export const TeamsCatalogPage = teamsCatalogPlugin.provide(
  createComponentExtension({
    name: 'TeamsCatalogPage',
    component: {
      lazy: () =>
        import('./components/TeamsHomePage').then(m => m.TeamsHomePage),
    },
  }),
);
