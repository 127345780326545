import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { oauth2ApiRef } from 'plugin-core';
import {
  ComplianceAndSecurityBaseImageVulnerabilitiesApi,
  complianceAndSecurityBaseImageVulnerabilitiesApiRef,
} from './api/ComplianceAndSecurityBaseImageVulnerabilitiesApi';
import {
  ComplianceAndSecurityAwsSecurityHubApi,
  complianceAndSecurityAwsSecurityHubApiRef,
} from './api/ComplianceAndSecurityAwsSecurityHubApi';

export const complianceAndSecurityPlugin = createPlugin({
  id: 'compliance-and-security',
  routes: {
    root: rootRouteRef,
  },
  featureFlags: [{ name: 'security-dashboard-feature-flag' }],
  apis: [
    createApiFactory({
      api: complianceAndSecurityBaseImageVulnerabilitiesApiRef,
      deps: { oauth2Api: oauth2ApiRef, discoveryApi: discoveryApiRef },
      factory: ({ oauth2Api, discoveryApi }) =>
        new ComplianceAndSecurityBaseImageVulnerabilitiesApi({
          oauth2Api,
          discoveryApi,
        }),
    }),
    createApiFactory({
      api: complianceAndSecurityAwsSecurityHubApiRef,
      deps: { oauth2Api: oauth2ApiRef, discoveryApi: discoveryApiRef },
      factory: ({ oauth2Api, discoveryApi }) =>
        new ComplianceAndSecurityAwsSecurityHubApi({
          oauth2Api,
          discoveryApi,
        }),
    }),
  ],
});

export const ComplianceAndSecurityPage = complianceAndSecurityPlugin.provide(
  createRoutableExtension({
    name: 'ComplianceAndSecurityPage',
    component: () =>
      import('./components/ComplianceAndSecurityPage').then(
        m => m.ComplianceAndSecurityPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
