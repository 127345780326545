import { getEntityRelations } from '@backstage/plugin-catalog-react';
import { getTeamNameFromFullName } from '../string';
import { collectToer } from './collectors';

/** Extract all entities that match all those filter groups. */
export function buildMatchingEntities(
  filters: ICatalog.Filters,
  entities?: Array<IEntityComponent>,
  excludeFilterGroupId?: string,
): IEntityComponent[] {
  // Build one filter fn per filter group
  const allFilters: ICatalog.EntityFilterFn[] = [];
  for (const [filterGroupId, filterGroup] of Object.entries(
    filters.filterGroups,
  )) {
    if (excludeFilterGroupId === filterGroupId) {
      continue;
    }

    // Pick out all the filter functions in the group that are actually selected
    const groupFilters: ICatalog.EntityFilterFn[] = [];
    for (const [filterId, filterFn] of Object.entries(filterGroup.filters)) {
      if (!!filters.selectedFilterKeys[filterGroupId]?.has(filterId)) {
        groupFilters.push(filterFn);
      }
    }

    // Need to match any of the selected filters in the group - if there is any at all
    if (groupFilters.length) {
      allFilters.push(entity => groupFilters.some(fn => fn(entity)));
    }
  }

  // Filter by tags, if at least one tag is selected. Include all entities
  // that have at least one of the selected tags
  if (filters.tagsFilter && filters.tagsFilter.length > 0) {
    allFilters.push(
      entity =>
        !!entity.metadata.tags &&
        entity.metadata.tags.some(t => filters.tagsFilter.includes(t)),
    );
  }

  if (filters.cyberWeekPhaseFilter && filters.cyberWeekPhaseFilter.length > 0) {
    allFilters.push(
      entity =>
        !!entity.metadata.tags &&
        entity.metadata.tags.some(t =>
          filters.cyberWeekPhaseFilter.includes(t),
        ),
    );
  }

  if (filters.docsCategoryFilter && filters.docsCategoryFilter.length > 0) {
    allFilters.push(
      entity =>
        !!entity.spec.category &&
        filters.docsCategoryFilter.includes(entity?.spec?.category as string),
    );
  }

  if (filters.prrFilter) {
    allFilters.push(entity => {
      const ent = entity as IEntityApp;
      const val = ent.metadata.productionReadinessReviewDate;
      switch (filters.prrFilter) {
        case 'never':
          return val === 'never';
        default:
          // The value should be a date string
          return !!val && val !== 'never';
      }
    });
  }

  if (filters.statusFilter) {
    const isActive = filters.statusFilter === 'yes';
    allFilters.push(
      entity => !!(entity.spec?.spec as IEntitySpec)?.active === isActive,
    );
  }
  if (filters.journeyFilter && filters.journeyFilter.length) {
    allFilters.push(entity => {
      return filters.journeyFilter.some(filter =>
        (entity as IEntityTooling).spec.journey.includes(filter),
      );
    });
  }

  if (filters.stepFilter.length) {
    allFilters.push(entity => {
      return filters.stepFilter.some(filter =>
        (entity as IEntityTooling).spec.step.includes(filter),
      );
    });
  }

  if (filters.toolingStatusFilter.length) {
    allFilters.push(entity => {
      return filters.toolingStatusFilter.some(
        filter => (entity as IEntityTooling).spec.status === filter,
      );
    });
  }

  if (filters.ownerFilter.length) {
    allFilters.push(entity => {
      return filters.ownerFilter.some(
        filter => (entity as IEntityTooling).spec.owner === filter,
      );
    });
  }

  if (filters.criticalityFilter && filters.criticalityFilter !== 'all') {
    allFilters.push(
      entity => entity.spec?.lifecycle === filters.criticalityFilter,
    );
  }

  if (filters.cyberweekRelevantFilter) {
    const isCyberWeekRelevant = filters.cyberweekRelevantFilter === 'yes';
    allFilters.push(
      entity => !!entity.metadata.cyberweekInScope === isCyberWeekRelevant,
    );
  }

  if (filters.support247Filter) {
    const isSupport247 = filters.support247Filter === 'yes';
    allFilters.push(
      entity =>
        !!(entity.spec?.spec as IEntitySpec)?.incident_contact === isSupport247,
    );
  }

  if (filters.cyberWeekToerFilter.length) {
    allFilters.push(entity => {
      const toer = collectToer(entity);
      if (!!entity.metadata.cyberweekInScope) {
        return filters.cyberWeekToerFilter.includes(toer);
      }
      return false;
    });
  }

  if (filters.teamsFilter.length) {
    allFilters.push(entity => {
      let teamNameMatches = false;
      // get team name for application and api entities
      const appSpecSpec = entity.spec.spec as IEntityAPPSpecSpec;
      // get team name for team entity
      const fullTeamName = (entity.spec as IEntityGroupSpec)?.fullName;
      const possibleTeamNames = [
        appSpecSpec?.owner_name,
        appSpecSpec?.team_id,
        getTeamNameFromFullName(fullTeamName),
        getTeamNameFromFullName(fullTeamName, 'parentName'),
      ];
      possibleTeamNames.forEach(teamName => {
        // Fitler by team name or by orphaned entity if 'Invalid Team' is selected
        if (
          filters.teamsFilter.includes(teamName) ||
          (filters.teamsFilter.includes('Invalid Team') &&
            entity.spec.orphanedEntity)
        )
          teamNameMatches = true;
      });
      return teamNameMatches;
    });
  }

  if (filters.lifecycleFilter && filters.lifecycleFilter !== 'all') {
    allFilters.push(
      entity =>
        (entity as IEntityApp).spec?.lifecycle === filters.lifecycleFilter,
    );
  }

  if (filters.typeFilter && filters.typeFilter !== 'all') {
    allFilters.push(entity => entity.spec?.type === filters.typeFilter);
  }

  if (filters.costCenterFilter.length) {
    allFilters.push(entity => {
      const zalandoSpec = entity.spec?.zalando as IEntityZalandoSpec;
      return (
        !!zalandoSpec.costCenter &&
        filters.costCenterFilter.includes(zalandoSpec.costCenter)
      );
    });
  }

  if (filters.idsAndAliasesFilter.length) {
    allFilters.push(entity => {
      let aliasIsIncluded = false;
      const alias = (entity.spec as IEntityGroupSpec)?.alias;
      const teamId = (entity.spec as IEntityGroupSpec)?.team_id;
      if (!!alias?.length) {
        alias.forEach(a => {
          if (filters.idsAndAliasesFilter.includes(a)) aliasIsIncluded = true;
        });
      }
      return (
        aliasIsIncluded ||
        (!!teamId && filters.idsAndAliasesFilter.includes(teamId))
      );
    });
  }

  if (filters.reviewFilter && filters.reviewFilter.length) {
    allFilters.push(entity => {
      let result = false;
      const metadata = entity.metadata as IEntityAppMetadata;
      const filter = filters.reviewFilter;

      const isReviewed: boolean =
        !metadata.lastReview?.needsReview && !!metadata.lastReview?.time;
      const neverReviewed: boolean = !metadata.lastReview?.time;
      const needsReview: boolean =
        !!metadata.lastReview?.needsReview && !!metadata.lastReview?.time;

      result = result || (filter.includes('reviewed') && isReviewed);
      result = result || (filter.includes('needs review') && needsReview);
      result = result || (filter.includes('never') && neverReviewed);
      return result;
    });
  }

  if (
    filters.dataClassificationFilter &&
    filters.dataClassificationFilter.length
  ) {
    allFilters.push(entity => {
      const metadata = entity.metadata as IEntityAppMetadata;
      return (
        !!metadata.lastReview?.dataClassification &&
        filters.dataClassificationFilter.includes(
          metadata.lastReview?.dataClassification.toLowerCase() as IAppReview.DataClassification,
        )
      );
    });
  }

  if (filters.apecFilter && filters.apecFilter.length) {
    allFilters.push(entity => {
      const metadata = entity.metadata as IEntityAppMetadata;
      return filters.apecFilter.includes(metadata.apecStatus?.status);
    });
  }

  if (filters.scorecardsFilter.length) {
    const filterFn = (entity: IEntityComponent) => {
      const scorecardFilter = filters.scorecardsFilter;
      const scorecardRelations = getEntityRelations(
        entity,
        'consumesScorecard',
      );
      return scorecardRelations.some(rel => scorecardFilter.includes(rel.name));
    };
    allFilters.push(filterFn);
  }

  if (filters.securityTierFilter.length) {
    allFilters.push(entity => {
      const spec = entity.spec as IEntityAppSpec;
      const securityTier = spec.spec.security_tier;
      if (!securityTier) return false;
      return filters.securityTierFilter.some(f => Number(f) === securityTier);
    });
  }

  if (filters.techTeamsFilter) {
    allFilters.push(entity => {
      const spec = entity.spec as IEntityGroupSpec;
      switch (filters.techTeamsFilter.toLowerCase()) {
        case 'yes':
          return Boolean(spec.id);
        case 'no':
          return !spec.id;
        default:
          return true;
      }
    });
  }

  // All filter groups that had any checked filters need to match. Note that
  // every() always returns true for an empty array.
  return entities?.filter(entity => allFilters.every(fn => fn(entity))) ?? [];
}
