export * from './plugin';
export * from './api';
export * from './styles';
export { updateVulnerabilitiesCount, getOpenVulnerabilities } from './helpers';

export const vulnerabilityAlertAction =
  'You should upgrade to a secure version of the package as soon as possible.';
export const vulnerabilityAlertDescription = `Dependabot alerts tell you that your code 
depends on a package that is insecure.If your code depends on a package with a security vulnerability, this can cause
 a range of problems for your project or the people who use it.`;
