import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './router';

export const supportPlugin = createPlugin({
  id: 'support',
  routes: {
    root: rootRouteRef,
  },
});

export const SupportRouter = supportPlugin.provide(
  createRoutableExtension({
    name: 'Router',
    component: () => import('./router/Router').then(m => m.SupportRouter),
    mountPoint: rootRouteRef,
  }),
);
