import { DiscoveryApi } from '@backstage/core-plugin-api';
import { ContainerImagesApi } from './interface';
import * as resp from './response';

const knownRegistries = [
  'container-registry.zalando.net',
  'container-registry-test.zalando.net',
  'eu-west-1.container-registry.zalando.net',
  'eu-west-1.container-registry-test.zalando.net',
  'pierone.stups.zalan.do',
  'registry.opensource.zalan.do',
];

export function isKnownRegistry(registry: string): boolean {
  return knownRegistries.includes(registry);
}

export class ContainerImagesClient implements ContainerImagesApi {
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = options.discoveryApi;
  }

  private async get<T>(path: string, params: any = {}): Promise<T> {
    const url: string = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/${path}`;
    const urlParams: URLSearchParams = new URLSearchParams(params);
    try {
      const response = await fetch(`${url}?${urlParams}`, {
        credentials: 'include',
      });
      return await this.parse(response);
    } catch (e) {
      throw e;
    }
  }

  private async parse(response: Response): Promise<any> {
    let jsonResp;
    switch (true) {
      case response.status === 400:
        jsonResp = await response.json();
        throw new Error(jsonResp.detail || 'Bad request');
      case response.status > 400:
      case !response.ok:
        throw new Error(response.statusText);
      default:
        return await response.json();
    }
  }

  private getRegistryProxyName(registry: string): string {
    switch (registry) {
      case 'container-registry.zalando.net':
        return 'container-registry';
      case 'container-registry-test.zalando.net':
        return 'container-registry-test';
      case 'eu-west-1.container-registry.zalando.net':
        return 'eu-west-1-container-registry';
      case 'eu-west-1.container-registry-test.zalando.net':
        return 'eu-west-1-container-registry-test';
      case 'pierone.stups.zalan.do':
        return 'pierone';
      case 'registry.opensource.zalan.do':
        return 'os-registry';
      default:
        throw new Error(`Unknown registry: ${registry}`);
    }
  }

  async getImage(
    registry: string,
    path: string,
    tag: string,
  ): Promise<resp.Image> {
    if (!isKnownRegistry(registry)) {
      throw new Error(`Unknown registry: ${registry}`);
    }
    return await this.get(
      `container-metadata/image-metadata/${registry}/${path}:${tag}`,
    );
  }

  async getImageTags(registry: string, path: string): Promise<resp.ImageTags> {
    if (!isKnownRegistry(registry)) {
      throw new Error(`Unknown registry: ${registry}`);
    }
    const proxyName = this.getRegistryProxyName(registry);
    return await this.get(`${proxyName}/v2/${path}/tags/list`);
  }
}
