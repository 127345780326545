import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { containerImagesApiRef } from 'plugin-core';

export function useContainerImageInfo(image: string, tag: string) {
  const api = useApi(containerImagesApiRef);

  const {
    loading,
    error,
    value: imageDetails,
  } = useAsync(async () => {
    const registry = image.split('/')[0];
    const path = image.substring(image.indexOf('/') + 1);
    return await api.getImage(registry, path, tag);
  }, []);

  return {
    loading,
    imageDetails,
    error,
  };
}
