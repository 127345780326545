import React from 'react';
import { Link } from 'react-router-dom';
import Launch from '@material-ui/icons/Launch';
import { Tooltip } from '../../../common';
import { CircularProgress } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { useContainerImageInfo } from '../../../hooks/useContainerImageInfo';

type Props = {
  name: string;
  deps_vars: { [key: string]: string };
};

export function fullDependencyImageName(
  name: string,
  deps_vars: { [key: string]: string },
): string | null {
  const dependencyImageName = deps_vars[`DEP_${name.toUpperCase()}_REF`];
  const dependencyImageVersion = deps_vars[`DEP_${name.toUpperCase()}_VERSION`];

  if (!!dependencyImageName && !!dependencyImageVersion) {
    return `${dependencyImageName}:${dependencyImageVersion}`;
  }
  return null;
}

function DependencyTriggerFullInfo({ image }: { image: string }) {
  const [name, version] = image.split(':');
  const { loading, imageDetails, error } = useContainerImageInfo(name, version);

  return (
    <span>
      <span>{`Triggered by dependency: ${image}`}</span>

      <span>&nbsp;</span>

      {!error && (
        <Tooltip title="build pipeline">
          <LoadingButton
            style={{
              minWidth: 0,
              padding: '0px 6px',
              paddingTop: '3px',
              lineHeight: '20px',
            }}
            size="small"
            disabled={loading || !imageDetails}
            loading={loading}
            loadingIndicator={<CircularProgress color="inherit" size={14} />}
            children={
              <Link
                target="blank"
                rel="noopener noreferrer"
                to={
                  !!imageDetails?.ci?.pipeline_id
                    ? `/cdp/pipeline/${imageDetails?.ci?.pipeline_id}`
                    : '#'
                }
              >
                <Launch fontSize="inherit" />
              </Link>
            }
          />
        </Tooltip>
      )}
    </span>
  );
}

export function DependencyTriggerInfo({ name, deps_vars }: Props) {
  const fullImageName = fullDependencyImageName(name, deps_vars);

  if (!!fullImageName) {
    return <DependencyTriggerFullInfo image={fullImageName} />;
  }

  return `Triggered by dependency: ${name}`;
}
