import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { ReviewApi, applicationReviewApiRef } from './api';
import { oauth2ApiRef } from 'plugin-core';

export const plugin = createPlugin({
  id: 'application-review',
  apis: [
    createApiFactory({
      api: applicationReviewApiRef,
      deps: { oauth2Api: oauth2ApiRef, discoveryApi: discoveryApiRef },
      factory: ({ oauth2Api, discoveryApi }) =>
        new ReviewApi({
          oauth2Api,
          discoveryApi,
        }),
    }),
  ],
});

// Lazy load the UI components
export const ApplicationReviewForm = plugin.provide(
  createComponentExtension({
    name: 'ApplicationReviewForm',
    component: {
      lazy: () => import('./components').then(m => m.ApplicationReviewForm),
    },
  }),
);

export const ApplicationReviewSummary = plugin.provide(
  createComponentExtension({
    name: 'ApplicationReviewSummary',
    component: {
      lazy: () => import('./components').then(m => m.ApplicationReviewSummary),
    },
  }),
);

export const ApplicationReviewCard = plugin.provide(
  createComponentExtension({
    name: 'ApplicationReviewCard',
    component: {
      lazy: () => import('./components').then(m => m.ApplicationReviewCard),
    },
  }),
);
