import { createPlugin } from '@backstage/core-plugin-api';

import { rootRouteRef } from './router';

export const containerImagesPlugin = createPlugin({
  id: 'container-images',
  apis: [],
  routes: {
    root: rootRouteRef,
  },
});
