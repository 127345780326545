import { EventTrackerProps } from 'plugin-ui-components';

export const common: Pick<EventTrackerProps, 'plugin' | 'eventCategory'> = {
  plugin: 'teams-catalog-plugin',
  eventCategory: 'Teams Catalog Plugin',
};

export const clickTableItem = (
  item: string,
  value: string,
): EventTrackerProps => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: `Click on ${item}: ${value}`,
});
