import React from 'react';
import { NavigateProps, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

/**
 * This component is used to navigate to a new route with the current search params preserved.
 */
export const NavigateWithParams = ({ to, ...props }: NavigateProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    navigate(
      {
        pathname: to.toString(),
        search: searchParams.toString(),
      },
      { ...props },
    );
  }, [navigate, to, props, searchParams]);

  return <></>;
};
