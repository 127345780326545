import { DiscoveryApi, OAuthApi } from '@backstage/core-plugin-api';

export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
}

export class ResponseError extends Error {
  violations: string[];
  constructor(message = 'Something went wrong', violations: string[] = []) {
    super();
    this.message = message;
    this.violations = violations;
  }
}

interface EnumViolationContext {
  expected: string;
}

interface EnumViolation {
  type: string;
  loc: string[];
  msg: string;
  input: string;
  ctx: EnumViolationContext;
}

type Detail = EnumViolation[][][];

interface Violation {
  field: string;
  message: string;
}

export interface ErrorResponse {
  status: number;
  violations: Violation[];
  title: string;
  type: string;
  detail?: Detail;
  message?: string;
  error_type?: string;
  resource_type?: string;
  resource_id?: string;
}

interface HTTPRequestParameters {
  method: HTTPMethod;
  path: string;
  body?: {};
  params?: {};
}

export class HttpClient {
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;
  private readonly pluginProxyEndpoint: string;

  constructor(options: {
    oauth2Api: OAuthApi;
    discoveryApi: DiscoveryApi;
    pluginProxyEndpoint: string;
  }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
    this.pluginProxyEndpoint = options.pluginProxyEndpoint;
  }

  private static buildHeaders(token: string): HeadersInit {
    return new Headers({
      authorization: `Bearer ${token}`,
      accept: 'application/json',
      'content-type': 'application/json',
    });
  }

  private async buildUrl(path: string): Promise<string> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return `${proxyUrl}/${this.pluginProxyEndpoint}${path}`;
  }

  private static fetchToJson(res: Response) {
    return res.headers.get('content-type') === 'application/json'
      ? res.json()
      : res.text();
  }

  private static handleFailure(response: any) {
    return response.then((errorResponse: ErrorResponse | string) => {
      let violationMessages: string[] = [];

      if (typeof errorResponse === 'object') {
        if (Array.isArray(errorResponse.violations)) {
          violationMessages = errorResponse.violations.map(
            violation => `${violation.field} : ${violation.message}`,
          );
        } else if (errorResponse.detail) {
          if (Array.isArray(errorResponse.detail)) {
            errorResponse.detail.forEach((item: any) =>
              item.forEach((nestedItem: any) =>
                nestedItem.forEach((error: any) =>
                  violationMessages.push(`${error.loc?.[1]} : ${error.msg}`),
                ),
              ),
            );
          } else {
            violationMessages.push(errorResponse.detail);
          }
        } else if (!violationMessages.length && errorResponse.message) {
          violationMessages.push(errorResponse.message);
        }

        const errorTitle = errorResponse.title || 'Request failed';
        throw new ResponseError(errorTitle, violationMessages);
      }

      throw new ResponseError(`Please try again: ${errorResponse}`);
    });
  }

  private static handleResponse(response: Response) {
    const jsonResponse = HttpClient.fetchToJson(response);
    if (response.ok) {
      return jsonResponse;
    }
    return HttpClient.handleFailure(jsonResponse);
  }

  private async makeRequest({
    method,
    path,
    body,
    params,
  }: HTTPRequestParameters) {
    let url: string = await this.buildUrl(path);
    const token: string = await this.oauth2Api.getAccessToken();
    if (params) {
      url += `?${new URLSearchParams(params).toString()}`;
    }

    return await fetch(url, {
      method: method,
      headers: HttpClient.buildHeaders(token),
      body: JSON.stringify(body),
      credentials: 'include',
    }).then(response => {
      return HttpClient.handleResponse(response);
    });
  }

  public get(path: string, params?: {}) {
    return this.makeRequest({
      method: HTTPMethod.GET,
      path: path,
      params: params,
    });
  }

  public patch(path: string, body: any = {}) {
    return this.makeRequest({
      method: HTTPMethod.PATCH,
      path: path,
      body: body,
    });
  }

  public post(path: string, body: any = {}) {
    return this.makeRequest({
      method: HTTPMethod.POST,
      path: path,
      body: body,
    });
  }

  public cleanUpParameters(parameters: any) {
    return Object.fromEntries(
      Object.entries(parameters).filter(
        ([_, value]) => value !== undefined && value !== '',
      ),
    );
  }
}
