import React from 'react';
import { Link, SubvalueCell, TableColumn } from '@backstage/core-components';
import { getTeamNameFromFullName } from 'plugin-core';
import {
  EventTracker,
  TeamReference,
  UserReference,
} from 'plugin-ui-components';
import { clickTableItem } from './tracking';
import { createActionsColumn } from './shared';

export const createIdAndAliasColumn = (): TableColumn<
  ICatalog.EntityRow<IEntityGroup>
> => ({
  title: 'ID & Aliases',
  field: 'entity.spec.alias',
  customFilterAndSearch: (query, row) => {
    const team_id = row.entity.spec.team_id;
    const alias = row.entity.spec?.alias?.join(', ');
    const compoundName = [team_id, alias].filter(i => !!i).join(' ');
    return compoundName
      .toLocaleUpperCase('en-US')
      .includes(query.toLocaleUpperCase('en-US'));
  },
  highlight: true,
  render: ({ entity }): React.ReactNode => {
    if (entity.spec.alias) {
      return (
        <SubvalueCell
          value={entity.spec.team_id}
          subvalue={entity.spec.alias.join(', ')}
        />
      );
    }
    return <>{entity.spec.team_id}</>;
  },
  sorting: false,
});

export const createTeamNameColumn = (
  order: 'asc' | 'desc' | '' = 'asc',
): TableColumn<ICatalog.EntityRow<IEntityGroup>> => ({
  title: 'Team Name',
  field: 'entity.spec.fullName',
  highlight: true,
  defaultSort: order as any,
  customSort: (rowData1, rowData2) => {
    const name1 = getTeamNameFromFullName(rowData1.entity.spec.fullName);
    const name2 = getTeamNameFromFullName(rowData2.entity.spec.fullName);
    return name1.toLowerCase() < name2.toLowerCase() ? -1 : 1;
  },
  render: ({ entity }): React.ReactNode => (
    <EventTracker {...clickTableItem('Team Name', entity.spec.fullName)}>
      <TeamReference team={entity} format="nameOnly" />
    </EventTracker>
  ),
});

export const createEmailColumn = (): TableColumn<
  ICatalog.EntityRow<IEntityGroup>
> => ({
  title: 'Email',
  field: 'entity.spec.mail.0',
  render: ({ entity }) =>
    entity.spec.mail.length ? (
      <EventTracker {...clickTableItem('Email', entity.spec.mail[0])}>
        <Link target="_blank" to={`mailto:${entity.spec.mail[0]}`}>
          {entity.spec.mail[0]}
        </Link>
      </EventTracker>
    ) : (
      '-'
    ),
  sorting: false,
});

export const createMembersColumn = (): TableColumn<
  ICatalog.EntityRow<IEntityGroup>
> => ({
  title: 'Members',
  field: 'entity.spec.members',
  sorting: false,
  render: ({ entity }): React.ReactNode => (
    <>{entity.spec?.members?.length ?? 0}</>
  ),
});

export const createTeamLeadColumn = (): TableColumn<
  ICatalog.EntityRow<IEntityGroup>
> => ({
  title: 'Team Lead',
  field: 'entity.spec.delivery_lead',
  sorting: false,
  render: ({ entity }) => (
    <UserReference
      user={entity.spec.delivery_lead}
      tracking={clickTableItem('Team Lead', entity.spec?.delivery_lead || '')}
    />
  ),
});

export const createParentTeamColumn = (): TableColumn<
  ICatalog.EntityRow<IEntityGroup>
> => ({
  title: 'Parent Team',
  field: 'entity.spec.fullName',
  render: ({ entity }): React.ReactNode => (
    <TeamReference
      team={getTeamNameFromFullName(entity.spec.fullName, 'parentName')}
      format="nameOnly"
    />
  ),
  sorting: false,
});

export const createCostCenterColumn = (): TableColumn<
  ICatalog.EntityRow<IEntityGroup>
> => ({
  title: 'Cost Center',
  field: 'entity.spec.zalando.costCenter',
  sorting: false,
});

export const teamsColumns = [
  createIdAndAliasColumn(),
  createTeamNameColumn(),
  createEmailColumn(),
  createMembersColumn(),
  createTeamLeadColumn(),
  createParentTeamColumn(),
  createCostCenterColumn(),
  createActionsColumn(),
];
