import { EventTrackerProps } from 'plugin-ui-components';

export const common: Pick<EventTrackerProps, 'plugin' | 'eventCategory'> = {
  plugin: 'teams-catalog-plugin',
  eventCategory: 'Teams Catalog Plugin',
};

export const addFilter = (
  label: string,
  values: string[],
): EventTrackerProps => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: `Filter by ${label}: ${values.join(', ')}`,
});
