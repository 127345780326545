import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { oauth2ApiRef } from 'plugin-core';
import { LibrariesApi, librariesApiRef } from './api';

export const dependenciesPlugin = createPlugin({
  id: 'dependencies',
  featureFlags: [{ name: 'dependencies-plugin' }],
  apis: [
    createApiFactory({
      api: librariesApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        authApi: oauth2ApiRef,
      },
      factory: ({ discoveryApi, authApi }) =>
        new LibrariesApi({ discoveryApi, authApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const DependenciesRouter = dependenciesPlugin.provide(
  createRoutableExtension({
    name: 'DependenciesRouter',
    component: () => import('./Router').then(m => m.DependenciesRouter),
    mountPoint: rootRouteRef,
  }),
);

export const DependenciesStandAloneRouter = dependenciesPlugin.provide(
  createRoutableExtension({
    name: 'DependenciesStandAloneRouter',
    component: () =>
      import('./Router').then(m => m.DependenciesStandAloneRouter),
    mountPoint: rootRouteRef,
  }),
);
