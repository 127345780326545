import {
  createComponentExtension,
  createPlugin,
  createRouteRef,
} from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'cost-insights',
});

export const projectGrowthAlertRef = createRouteRef({
  id: 'cost-insights:investigating-growth',
});

export const unlabeledDataflowAlertRef = createRouteRef({
  id: 'cost-insights:labeling-jobs',
});

export const costInsightsPlugin = createPlugin({
  id: 'cost-insights',
  routes: {
    root: rootRouteRef,
    growthAlerts: projectGrowthAlertRef,
    unlabeledDataflowAlerts: unlabeledDataflowAlertRef,
  },
});

// Lazy load the UI components
export const CostInsightsPage = costInsightsPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () => import('./components').then(m => m.CostInsightsPage),
    },
  }),
);

export const CostInsightsProjectGrowthInstructionsPage =
  costInsightsPlugin.provide(
    createComponentExtension({
      component: {
        lazy: () =>
          import('./components').then(m => m.ProjectGrowthInstructionsPage),
      },
    }),
  );

export const CostInsightsLabelDataflowInstructionsPage =
  costInsightsPlugin.provide(
    createComponentExtension({
      component: {
        lazy: () =>
          import('./components').then(m => m.LabelDataflowInstructionsPage),
      },
    }),
  );
