import React from 'react';
import { Link, TableColumn } from '@backstage/core-components';
import { createNameDescriptionColumn, createOwnerColumn } from './shared';
import { DocCategoryChip } from '../../../DocCategoryChip';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

export const createDocCategoryColumn = (): TableColumn<ICatalog.EntityRow> => {
  return {
    title: 'Category',
    field: 'entity.spec.category',
    render: ({ entity }) => {
      const { category } = entity.spec;
      if (!category || category === 'unknown') return <></>;
      return <DocCategoryChip category={category as string} />;
    },
  };
};

export function createDocLinksColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Helpful links',
    width: '0',
    render: ({ entity }) => {
      const {
        kind,
        metadata: { name, namespace, external },
      } = entity as any as IEntityDoc;

      return (
        <Box display="flex" alignItems="center">
          <Tooltip
            title={
              external
                ? 'There is not embedded view of this documentation'
                : 'Open embedded documentation'
            }
            arrow
            placement="top-end"
          >
            <Link to={external ? '#' : `/docs/${namespace}/${kind}/${name}`}>
              <IconButton size="small" disabled={external}>
                <MenuBookIcon style={{ fontSize: '1.5rem' }} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip
            title="Open external documentation page"
            arrow
            placement="top-end"
          >
            <Link to={`https://${name}.docs.zalando.net`}>
              <IconButton size="small">
                <OpenInNewIcon style={{ fontSize: '1.5rem' }} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="View metadata" arrow placement="top-end">
            <Link to={`/catalog/${namespace}/${kind}/${name}`}>
              <IconButton size="small">
                <VisibilityIcon style={{ fontSize: '1.5rem' }} />
              </IconButton>
            </Link>
          </Tooltip>
        </Box>
      );
    },
  };
}

export const docsColumns: TableColumn<ICatalog.EntityRow>[] = [
  createNameDescriptionColumn(),
  createOwnerColumn(),
  createDocCategoryColumn(),
  createDocLinksColumn(),
];
