import React from 'react';
import {
  ClickAwayListener,
  makeStyles,
  Tooltip,
  TooltipProps,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

type InfoTooltipProps = Omit<TooltipProps, 'children' | 'title'> & {
  children: TooltipProps['title'];
  icon?: typeof HelpIcon;
  size?: string; // Optional prop to manage the size of the icon
};

const useStyles = makeStyles({
  svg: {
    fontSize: (props: { size?: string }) => props.size || '1em',
    verticalAlign: 'middle',
    margin: '0 0 0.2em 0.2em',
    cursor: 'pointer',
  },
});

export function InfoTooltip({ children, size, ...props }: InfoTooltipProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  const styles = useStyles({ size });
  const Icon = props.icon || HelpIcon;

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        {...props}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        interactive
        open={open}
        title={children}
        arrow
      >
        <Icon className={styles.svg} onClick={() => setOpen(o => !o)} />
      </Tooltip>
    </ClickAwayListener>
  );
}
