import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Link, TableColumn } from '@backstage/core-components';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Markdown from 'markdown-to-jsx';
import {
  createActionsColumn,
  createMetadataDescriptionColumn,
  createOwnerColumn,
} from './shared';

const getApiSummary = (apiSummary: string | undefined) => {
  return apiSummary
    ? apiSummary
    : `Unfortunately we aren't able to parse the description of your API.`;
};

export function createNameColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Name',
    field: 'resolved.name',
    highlight: true,
    render: ({ entity }) => {
      const isActive = entity.spec?.spec?.active;
      const {
        kind = 'Component',
        metadata: { namespace = 'default', name },
      } = entity;
      return (
        <div>
          {isActive !== undefined && (
            <Tooltip title={isActive ? 'active' : 'inactive'}>
              <FiberManualRecord
                className="dot-icon"
                data-active={String(isActive)}
              />
            </Tooltip>
          )}
          <Link to={`/catalog/${namespace}/${kind}/${name}`}>{name}</Link>
        </div>
      );
    },
  };
}

export function createSpecTypeColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Type',
    field: 'entity.spec.type',
    width: 'auto',
  };
}

export function createLifecycleColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Lifecycle',
    field: 'entity.spec.lifecycle',
    width: 'auto',
    searchable: false,
  };
}

export function createMetadataSummaryColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Summary',
    field: 'entity.metadata.summary',
    render: ({ entity }) => (
      <Tooltip
        title={
          <Markdown>
            {getApiSummary(entity.metadata?.summary as string)}
          </Markdown>
        }
        placement="bottom-start"
      >
        <span>{getApiSummary(entity.metadata?.summary as string)}</span>
      </Tooltip>
    ),
    searchable: true,
  };
}

export function createSystemColumn(): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'System',
    field: 'resolved.partOfSystemRelationTitle',
    width: 'auto',
  };
}

const sharedColumns = [
  createNameColumn(),
  createOwnerColumn(),
  createLifecycleColumn(),
  createSpecTypeColumn(),
];

export const apiColumns: TableColumn<ICatalog.EntityRow>[] = [
  ...sharedColumns,
  createMetadataSummaryColumn(),
  createActionsColumn(),
];

export const consumeColumns: TableColumn<ICatalog.EntityRow>[] = [
  ...sharedColumns,
  createSystemColumn(),
  createMetadataDescriptionColumn(),
];
